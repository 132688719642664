<template>
    <div id="FooterCarousel">
        <Carousel ref="carousel" :items-to-show="2.4" :wrapAround="true">
            <Slide
                v-for="(item, index) in footerCarouselImageUrls"
                :key="'image' + index"
            >
                <img v-lazy="item" />
            </Slide>
            <template #addons>
                <pagination />
            </template>
        </Carousel>
        <i
            id="navigation-left"
            class="navigation icon-prev"
            @click="carousel.prev()"
        ></i>
        <i
            id="navigation-right"
            class="navigation icon-next"
            @click="carousel.next()"
        ></i>
    </div>
</template>
<script>
import { ref } from 'vue'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import '../style/carousel.css'
export default {
    name: 'FooterCarousel',
    components: { Carousel, Slide, Pagination },
    props: { footerCarouselImageUrls: Array },
    setup() {
        let carousel = ref(null)
        return { carousel }
    },
}
</script>
<style lang="scss" scoped>
#FooterCarousel {
    position: relative;
    overflow: hidden;
    margin-top: 40px;
    img {
        width: 100%;
        padding: 0 12.5px;
    }
    .navigation {
        position: absolute;
        display: block;
        width: 60px;
        height: 90px;
        color: white;
        text-align: center;
        font-size: 30px;
        line-height: 90px;
        background-color: rgba($color: $black, $alpha: 0.5);
        top: calc(50% - 20px);
        transform: translate(0, -50%);
        transition: 0.3s;
        cursor: pointer;

        &:hover {
            background-color: rgba($color: $black, $alpha: 0.75);
        }
    }
    #navigation-left {
        left: 0;
    }
    #navigation-right {
        right: 0;
    }
}
</style>
