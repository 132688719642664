export default function () {
    const footerHomePages = [
        {
            title: '貴き親理のお授け',
            description:
                '『大親神大生命』がお授け下さっておられます\n貴き親理のお授けの内容の一部を\n文章で公開させて頂いております',
            routeUrl: { name: 'Doctrine' },
            imgUrl: require('@/assets/background/background-3.jpg'),
            imgUrlMb: require('@/assets/background/background-3-mb.jpg'),
        },
        {
            title: '大自然界の活動',
            description:
                '「大自然界」の活動内容をご紹介しています。\n「生命の大親」に親感謝して生きる、という\n地球の新しい生き方を一緒に始めてみましょう！',
            routeUrl: { name: 'Activities' },
            imgUrl: require('@/assets/background/background-4.jpg'),
            imgUrlMb: require('@/assets/background/background-4-mb.jpg'),
        },
        {
            title: 'おつなぎ\n徳積みの理',
            description:
                '『大親神大生命』にお受取り頂けるよう\n人のため世のために行わせて頂く\nプラスの種まきです。',
            routeUrl: { name: 'Donate' },
            imgUrl: require('@/assets/background/background-5.jpg'),
            imgUrlMb: require('@/assets/background/background-5-mb.jpg'),
        },
    ]
    const footerPages = [
        {
            title: '貴き親理のお授け',
            description:
                '『大親神大生命』がお授け下さっておられます\n貴き親理のお授けの内容の一部を\n文章で公開させて頂いております',
            routeUrl: { name: 'Doctrine' },
            imgUrl: require('@/assets/background/background-3.jpg'),
            imgUrlMb: require('@/assets/background/background-3-mb.jpg'),
        },
        {
            title: '大自然界の活動',
            description:
                '「大自然界」の活動内容をご紹介しています。\n「生命の大親」に親感謝して生きる、という\n地球の新しい生き方を一緒に始めてみましょう！',
            routeUrl: { name: 'Activities' },
            imgUrl: require('@/assets/background/background-4.jpg'),
            imgUrlMb: require('@/assets/background/background-4-mb.jpg'),
        },
        {
            title: '基本セミナー\n申し込み',
            description: '基本セミナーについてご紹介します。',
            routeUrl: { name: 'Seminar' },
            imgUrl: require('@/assets/background/background-5.jpg'),
            imgUrlMb: require('@/assets/background/background-5-mb.jpg'),
        },
    ]
    const footerLinks = [
        {
            imgUrl: require('@/assets/footer/link-1.jpg'),
            imgUrlMb: require('@/assets/footer/link-1-mb.jpg'),
            routeUrl: { name: 'Book' },
        },
        {
            imgUrl: require('@/assets/footer/link-2.jpg'),
            imgUrlMb: require('@/assets/footer/link-2-mb.jpg'),
            href: 'https://www.youtube.com/channel/UC86wUTSbA9Z25zO523GmZ5Q',
        },
        {
            imgUrl: require('@/assets/footer/link-3.jpg'),
            imgUrlMb: require('@/assets/footer/link-3-mb.jpg'),
            href: 'https://ameblo.jp/daishizenkai-official',
        },
        {
            imgUrl: require('@/assets/footer/link-4.jpg'),
            imgUrlMb: require('@/assets/footer/link-4-mb.jpg'),
            routeUrl: { name: 'QA' },
        },
        {
            imgUrl: require('@/assets/footer/link-5.jpg'),
            imgUrlMb: require('@/assets/footer/link-5-mb.jpg'),
            routeUrl: { name: 'Thanks' },
        },
    ]
    const footerCarouselImageUrls = [
        require('@/assets/carousel/carousel-2.jpg'),
        require('@/assets/carousel/carousel-3.jpg'),
        require('@/assets/carousel/carousel-4.jpg'),
        require('@/assets/carousel/carousel-5.jpg'),
        require('@/assets/carousel/carousel-6.jpg'),
        require('@/assets/carousel/carousel-7.jpg'),
        require('@/assets/carousel/carousel-8.jpg'),
        require('@/assets/carousel/carousel-9.jpg'),
        require('@/assets/carousel/carousel-10.jpg'),
        require('@/assets/carousel/carousel-11.jpg'),
        require('@/assets/carousel/carousel-12.jpg'),
        require('@/assets/carousel/carousel-1.jpg'),
    ]
    const footerSites = [
        {
            title: '個人情報保護方針',
            routeUrl: {
                name: 'PrivacyPolicy',
            },
        },
        {
            title: 'ご利用規約',
            routeUrl: {
                name: 'SitePolicy',
            },
        },
        {
            title: '特定商取引法に基づく表記',
            routeUrl: {
                name: 'CommercialPolicy',
            },
        },
        {
            title: '大自然界の基本情報',
            routeUrl: {
                name: 'BasicInfo',
            },
        },
        {
            title: 'お問い合わせ',
            routeUrl: {
                name: 'Join',
            },
        },
    ]

    return {
        footerHomePages,
        footerPages,
        footerLinks,
        footerCarouselImageUrls,
        footerSites,
    }
}
