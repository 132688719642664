<template>
    <div id="FooterSection">
        <section id="footer-youtube">
            <div id="footer-youtube-wrap">
                <LiteYouTubeEmbed id="5LYKvmW3AHQ" title="" />
            </div>
            <p class="f18 f14-mb w70-mb">
                「大自然界」で実際に学修させて頂くお授けです。（50分）
            </p>
        </section>
        <section id="footer-pages">
            <FooterPages
                :footerHomePages="footerHomePages"
                :footerPages="footerPages"
            ></FooterPages>
        </section>
        <section id="footer-news" v-lazy:background-image="newsImgUrl">
            <div id="footer-news-wrap" class="w40">
                <h2 class="f29 f22-mb">お知らせ</h2>
                <ul class="f16 f14-mb">
                    <li
                        v-for="(item, index) in newsReverse.slice(0, 3)"
                        :key="'news' + index"
                    >
                        <router-link
                            :to="{
                                name: 'News',
                                params: { newsId: item.id },
                            }"
                        >
                            <span class="date serif">{{ item.date }}</span>
                            <span class="title">{{ item.title }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </section>
        <section id="footer-links" class="w80 w80-mb">
            <ul>
                <li
                    v-for="(link, index) in footerLinks"
                    :key="'footer-link-' + index"
                >
                    <router-link v-if="link.routeUrl" :to="link.routeUrl">
                        <img
                            v-lazy="link.imgUrl"
                            :data-srcset="`${link.imgUrlMb} 652w, ${link.imgUrl} 970w`"
                            alt=""
                        />
                    </router-link>
                    <a v-else-if="link.href" :href="link.href" target="_blank">
                        <img
                            v-lazy="link.imgUrl"
                            :data-srcset="`${link.imgUrlMb} 652w, ${link.imgUrl} 970w`"
                            alt=""
                        />
                    </a>
                </li>
            </ul>
        </section>
        <section id="footer-carousel" class="hide-mb">
            <h2 class="f29">活動の写真</h2>
            <FooterCarousel
                :footerCarouselImageUrls="footerCarouselImageUrls"
            ></FooterCarousel>
        </section>
        <section id="footer-site">
            <ul class="serif f14 f14-mb w80 w80-mb">
                <li v-for="site in footerSites" :key="site.title">
                    <router-link :to="site.routeUrl">{{
                        site.title
                    }}</router-link>
                </li>
            </ul>
        </section>
        <section id="footer-copyright">
            <address>
                <a
                    href="https://www.youtube.com/channel/UC86wUTSbA9Z25zO523GmZ5Q"
                    target="_blank"
                    ><i class="icon-sns-youtube"></i
                ></a>
                <a
                    href="https://www.facebook.com/daishizenkai.official/"
                    target="_blank"
                    ><i class="icon-sns-facebook"></i
                ></a>
                <a
                    href="https://ameblo.jp/daishizenkai-official/"
                    target="_blank"
                    ><i class="icon-sns-blog"></i
                ></a>
            </address>
            <p class="f13 f12-mb serif">
                Copyright©DAISHIZENKAI. All Rights Reserved.
            </p>
        </section>
    </div>
</template>
<script>
import { computed } from 'vue-demi'
import FooterPages from './FooterPages.vue'
import FooterCarousel from './FooterCarousel.vue'
import getFooterData from '@/data/getFooterData'
import getNewsData from '@/data/getNewsData'
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/dist/style.css'
export default {
    name: 'FooterSection',
    components: { FooterPages, FooterCarousel, LiteYouTubeEmbed },
    setup() {
        const newsImgUrl = computed(() => {
            if (window.innerWidth < 768)
                return require('@/assets/footer/news-mb.jpg')
            else return require('@/assets/footer/news.jpg')
        })
        const {
            footerHomePages,
            footerPages,
            footerLinks,
            footerCarouselImageUrls,
            footerSites,
        } = getFooterData()
        const { news } = getNewsData()
        const newsReverse = news.slice().reverse()
        return {
            footerHomePages,
            footerPages,
            footerLinks,
            footerCarouselImageUrls,
            footerSites,
            news,
            newsReverse,
            newsImgUrl,
        }
    },
}
</script>
<style lang="scss" scoped>
#FooterSection {
    background-color: $minorGold;
}

section {
    @media screen and (min-width: 768px) {
        padding-top: 80px;
    }
    @media screen and (max-width: 767px) {
        padding-top: 50px;
    }
}
#footer-youtube {
    p {
        margin-top: 20px;
        text-align: center;
    }
    @media screen and (min-width: 768px) {
        #footer-youtube-wrap {
            margin: 0 auto;
            width: 100%;
            max-width: 720px;
            height: 400px;
            box-shadow: $shadow2;
        }
    }
    @media screen and (max-width: 767px) {
        #footer-youtube-wrap {
            padding: 0 2vw;
            height: 54vw;
        }
    }
}
#footer-news {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        z-index: 0;
        width: 100%;
        left: 0;
        bottom: 0;
    }

    #footer-news-wrap {
        position: relative;
        z-index: 20;

        h2 {
            @include h1Style();
        }
        ul {
            margin-top: 40px;
            text-align: left;
            li {
                margin: 1em;
            }
        }
    }
    @media screen and (min-width: 768px) {
        background: center / cover no-repeat;
        padding: 120px 0 240px;
        &:after {
            height: 100%;
            background: linear-gradient(
                to bottom,
                rgba($minorGold, 0.5),
                transparent 50%
            );
        }
        #footer-news-wrap {
            background-color: rgba(white, 0.85);
            border-radius: 20px;
            padding: 30px 60px;
            a {
                display: flex;
                color: $gray0;
                transition: 0.3s;
                span.date {
                    flex: 0 0 120px;
                }
                span.title {
                    flex: 1;
                }
                &:hover {
                    color: lighten($gray0, 50%);
                }
            }
        }
    }
    @media screen and(max-width:767px) {
        background: bottom / contain no-repeat;
        padding-bottom: 200px;
        &:after {
            height: 200px;
            background: linear-gradient(to bottom, $white, rgba($white, 0.2));
        }
        #footer-news-wrap {
            background-color: $white;
            padding: 60px 30px 30px;
            a {
                display: block;
                transition: 0.3s;
                span {
                    display: block;
                }
                span.date {
                    color: $grayB;
                }
                span.title {
                    color: $gray6;
                }
                &:hover {
                    color: $gray9;
                }
            }
        }
    }
}
#footer-links {
    li {
        margin: 20px 0;
        box-shadow: $shadow1;

        img {
            display: block;
            width: 100%;
        }
    }
    @media screen and (min-width: 768px) {
        padding: 80px 0;
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
        li {
            flex: 0 0 45%;
        }
    }
    @media screen and(max-width:767px) {
        padding: 50px 0;
    }
}
#footer-carousel {
    @media screen and (min-width: 768px) {
        padding-bottom: 80px;
        background-color: $white;
        h2 {
            @include h1Style();
        }
    }
}
#footer-site {
    padding: 40px 0 80px;
    background-color: $majorPurple;
    a {
        display: block;
        color: white;
        margin: 0.5em 0;
        transition: 0.3s;

        &:hover {
            color: darken(white, 10%);
        }
    }
}
#footer-copyright {
    padding: 20px 0;
    text-align: center;
    background-color: $white;
    address {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            cursor: pointer;
            position: relative;
            margin: 10px 15px 20px;
            font-size: 28px;
            line-height: 1em;
            color: $grayB;
            transition: 0.3s;
            &:hover {
                color: $gray9;
            }
        }
    }
    p {
        color: $grayB;
        user-select: none;
    }
}
</style>
