export default function () {
    let id = 0
    const news = [
        {
            id: id++,
            date: '2022.07.13',
            title: 'Facebook公式ページ開設のお知らせ',
            imageURL: require('@/assets/news/news-0.jpg'),
            article:
                'この度、Facebookに大自然界の公式ページを開設いたしました。\n \
                『大親神大生命』の御存在と貴き親理のお授けを、少しでも多くの方にお伝えさせていただけるよう、情報を発信してまいります。大自然界ホームページの更新情報や、セミナー開催のお知らせなども発信していく予定です。\n \
                \n \
                【Facebook公式ページ】\n \
                <a href="https://www.facebook.com/daishizenkai.official" target="_blank">https://www.facebook.com/daishizenkai.official</a>\n \
                Facebookアカウントをお持ちの方は、是非ご登録ください。',
        },
        {
            id: id++,
            date: '2022.07.13',
            title: 'YouTube公式チャンネル開設のお知らせ',
            imageURL: require('@/assets/news/news-1.jpg'),
            article:
                'YouTubeに大自然界の公式チャンネルを開設いたしました。\n \
                \n \
                公式チャンネルでは、『大親神大生命』がお授け下さっておられます「貴き親理のお授け」の一部を公開しております。また、基本セミナーで実際に学修させていただく本編動画も公開しておりますので、是非ご覧ください。\n \
                \n \
                【Youtube公式チャンネル】\n \
                <a href="https://www.youtube.com/channel/UC86wUTSbA9Z25zO523GmZ5Q" target="_blank">https://www.youtube.com/channel/UC86wUTSbA9Z25zO523GmZ5Q</a>',
        },
        {
            id: id++,
            date: '2022.12.21',
            title: 'ホームページ　リニューアルのお知らせ',
            imageURL: require('@/assets/news/news-2.jpg'),
            article:
                'この度、ホームページを全面リニューアルいたしました。\n \
                今回のリニューアルでは、皆様により見やすく、分かりやすく情報をお伝えできるホームページとなるよう改善いたしました。\n \
                リニューアルに伴い、URLが変更になりましたのでブラウザの「ブックマーク」「お気に入り」などに登録されている場合は、新しいURLへの登録変更をお願いします。\n \
                \n \
                『大親神大生命』の御存在と貴き親理のお授けが地球に広まり、素晴らしい惑星となれますよう、これからもより良いサイトを目指してまいります。\n \
                今後とも何卒よろしくお願い申し上げます。',
        },
        {
            id: id++,
            date: '2024.05.19',
            title: '「大自然の大いなる生命の大親」 出版のお知らせ',
            imageURL: require('@/assets/news/news-3.jpg'),
            article:
                'この度、「大自然の大いなる生命の大親」が出版されました。\n \
                \n \
                本の詳細につきましては、「書籍のご案内」の<a href="https://daishizenkai.or.jp/book#book-1" target="_blank">「大自然の大いなる生命の大親」の紹介ページ</a> をご覧ください。\n \
                \n \
                この本は、『大親神大生命』の貴き親理のお授けのほんの一部を、私たちがわからせて頂いた範囲で記させて頂いたものです。\n \
                \n \
                『大親神大生命』のお授けを頂いて、私たちは初めて、全生物に生命を与え生かして下さっている「生命の大親」の御存在に気づかせて頂く事ができました。\n \
                \n \
                『大親神大生命』が行って下さっておられる大自然の事実を、一つ、また一つとわからせて頂くごとに、驚きと感動、そして親感謝の気持ちで一杯になります。そして、私たちはみな、「生命の大親」の「生命の家族」なのだ、という一体感が湧いてきます。\n \
                \n \
                地球上では、考え方や価値観の違いから人間同士で争い・対立し、戦争や紛争が絶えません。また、難民、貧困、環境破壊、かつてない異常気象や感染症など、様々な問題が起きております。\n \
                これらの問題を解決するには、人間の考え方や思想の違い、宗教・宗派、人種、民族、国の違いを超えて、大自然の真理法則を基準にした、新しい地球の価値基準が必要です。\n \
                \n \
                一人でも多くの方が、大自然の「大いなる生命の大親」の御存在と御愛を実感し、より素晴らしい人生を送られますように、そして人類が、大自然の真理・法則に沿った生き方に切り替わり、地球が平和で、喜びと親感謝・感動にあふれた星になりますよう、願っております。\n \
                \n \
                冒頭で申し上げた通り、この本に書かれている内容は、まだまだ至りません私たちが、わからせて頂いた範囲で記させて頂いたものです。そのため、大変申し訳ない事に、直接のお授けの何億分の一もお伝えする事ができません。\n \
                \n \
                この本を読んで下さった方には、ぜひ『大親神大生命』の直接のお授けを頂いて頂けたらと思います。\n \
                \n \
                >><a href="https://www.youtube.com/channel/UC86wUTSbA9Z25zO523GmZ5Q" target="_blank">大自然界 公式YouTubeチャンネル</a> （お授けの一部がYouTubeで公開されています）\n \
                >><a href="https://daishizenkai.or.jp/seminar" target="_blank">「大自然界 基本セミナー」のご案内</a>',
        },
    ]

    return { news }
}
