<template>
    <div id="FooterPages">
        <div
            id="footer-pages-home"
            class="w90 w90-mb"
            v-show="route.name === 'Home'"
        >
            <div
                class="pages-home-item"
                v-for="(item, index) in footerHomePages"
                :key="'pages-home-item-' + index"
            >
                <div
                    class="background"
                    v-lazy:background-image="
                        addLazyBackground(item.imgUrl, item.imgUrlMb)
                    "
                ></div>
                <main class="card">
                    <h2 class="f24 f22-mb" v-html="wrap(item.title)"></h2>
                    <i class="bar"></i>
                    <p class="f12 f12-mb" v-html="wrap(item.description)"></p>
                    <router-link class="f12 f13-mb" :to="item.routeUrl"
                        >詳しく見る</router-link
                    >
                </main>
            </div>
        </div>
        <div id="footer-pages-others" v-show="route.name !== 'Home'">
            <div
                v-for="(item, index) in footerPages"
                class="w70 w90-mb"
                :key="'pages-home-item-' + index"
            >
                <router-link
                    class="pages-others-item"
                    v-show="route.name !== item.routeUrl.name"
                    :to="item.routeUrl"
                >
                    <aside
                        v-lazy:background-image="
                            addLazyBackground(item.imgUrl, item.imgUrlMb)
                        "
                    ></aside>
                    <main>
                        <h2 class="f29 f16-mb" v-html="wrap(item.title)"></h2>
                        <p class="f12" v-html="wrap(item.description)"></p>
                    </main>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { addLazyBackground, wrap } from '@/utils/common'
export default {
    name: 'FooterPages',
    props: {
        footerHomePages: Array,
        footerPages: Array,
    },
    setup() {
        const route = useRoute()

        return { route, addLazyBackground, wrap }
    },
}
</script>
<style lang="scss" scoped>
.pages-home-item {
    position: relative;

    .background {
        background: center / cover no-repeat;
    }
    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        & > * {
            position: relative;
        }
    }
    i.bar {
        display: block;
        background-color: $majorPurple;
    }
    a {
        @include pageLinkButtonStyle($grayB, 2px);
        margin: 1em 0;
        padding: 0.2em 0.5em;
    }

    @media screen and (min-width: 768px) {
        margin: 75px auto;
        .background {
            height: 400px;
            border-radius: 30px;
        }
        &:nth-child(2n-1) {
            margin-left: 70px;
            .card {
                right: 80px;
                bottom: -30px;
            }
        }
        &:nth-child(2n) {
            margin-right: 70px;
            .card {
                left: 80px;
                bottom: -30px;
            }
        }
        .card {
            position: absolute;
            width: 300px;
            height: 300px;
            &:before {
                content: '';
                position: absolute;
                left: 15px;
                top: 0px;
                display: block;
                width: 270px;
                height: 270px;
                transform: rotate(-45deg);
                border-radius: 17px;
                background-color: $white;
                box-shadow: $shadow2;
            }
        }
        i.bar {
            margin: 10px 0;
            width: 105px;
            height: 8px;
        }
    }
    @media screen and (max-width: 767px) {
        margin: 50px auto;
        .background {
            height: 150px;
            border-radius: 10px;
        }
        .card {
            margin: 30px 0;
        }
        i.bar {
            margin: 5px 0 20px;
            width: 50px;
            height: 5px;
        }
    }
}
.pages-others-item {
    display: flex;

    color: $gray0;
    border: 2px solid $purpleA;
    box-shadow: $shadow2;
    background-color: white;

    aside {
        background: center / cover no-repeat;
    }
    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
            @include h1Style($majorPurple);
        }
        p {
            margin-top: 2em;
            text-align: center;
        }
    }
    @media screen and (min-width: 768px) {
        height: 300px;
        margin: 50px auto;
        aside {
            flex: 5 1 300px;
        }
        main {
            flex: 4 0 300px;
        }
    }
    @media screen and (max-width: 767px) {
        height: 150px;
        margin: 30px auto;
        aside {
            flex: 1;
        }
        main {
            flex: 1;
            p {
                display: none;
            }
        }
    }
}
</style>
